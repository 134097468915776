import { render, staticRenderFns } from "./printers.vue?vue&type=template&id=bbad0fa6&scoped=true&"
import script from "./printers.vue?vue&type=script&lang=js&"
export * from "./printers.vue?vue&type=script&lang=js&"
import style0 from "./printers.vue?vue&type=style&index=0&id=bbad0fa6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbad0fa6",
  null
  
)

export default component.exports