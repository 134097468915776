<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitEditPrinter">
      <div class="form-group">
        <label for="printerData_serialNumber">{{$t('popularWords.sn')}}</label>
        <input
            id="printerData_serialNumber"
            v-model="printerData.serial_number"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.serial_number.$error }"
        />
        <div v-if="submitted && $v.printerData.serial_number.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.serial_number.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.serial_number.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.serial_number.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 35
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_ip">IP</label>
        <input
            id="printerData_ip"
            v-model="printerData.ip"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.ip.$error }"
        />
        <div v-if="submitted && $v.printerData.ip.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.ip.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.ip.minLength">
                       {{$t('validatePhrases.minLength')}}: 10
                      </span>
          <span v-if="!$v.printerData.ip.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 20
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_port">{{$t('views.printers.port')}}</label>
        <input
            id="printerData_port"
            v-model="printerData.port"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.port.$error }"
        />
        <div v-if="submitted && $v.printerData.port.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.port.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.port.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.port.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 6
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_code">{{$t('views.printers.code')}}</label>
        <input
            id="printerData_code"
            v-model="printerData.code"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.code.$error }"
        />
        <div v-if="submitted && $v.printerData.code.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.code.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.code.minLength">
                        {{$t('validatePhrases.minLength')}}: 6
                      </span>
          <span v-if="!$v.printerData.code.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 6
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_login">{{$t('views.printers.login')}}</label>
        <input
            id="printerData_login"
            v-model="printerData.login"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.login.$error }"
        />
        <div v-if="submitted && $v.printerData.login.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.login.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.login.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.login.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 65
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_password">{{$t('views.printers.pass')}}</label>
        <input
            id="printerData_password"
            v-model="printerData.password"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.password.$error }"
        />
        <div v-if="submitted && $v.printerData.password.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.password.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.password.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.password.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 65
                      </span>
        </div>
      </div>
      <div @click="printerData.is_production = !printerData.is_production" class="custom-control custom-checkbox mb-3">
        <input v-model="printerData.is_production" type="checkbox" id="printerData_isProduction" class="custom-control-input">
        <label for="printerData_isProduction" class="custom-control-label">{{$t('views.printers.production')}}</label>
      </div>
      <div @click="printerData.is_color = !printerData.is_color" class="custom-control custom-checkbox mb-3">
        <input v-model="printerData.is_color" type="checkbox" id="printerData_isColor" class="custom-control-input">
        <label for="printerData_isColor" class="custom-control-label">{{$t('popularWords.color')}}</label>
      </div>
      <div @click="printerData.is_on = !printerData.is_on" class="custom-control custom-checkbox mb-3">
        <input v-model="printerData.is_on" type="checkbox" id="printerData_isOn" class="custom-control-input">
        <label for="printerData_isOn" class="custom-control-label">{{$t('views.printers.isOn')}}</label>
      </div>
      <div @click="printerData.show_map = !printerData.show_map" class="custom-control custom-checkbox mb-3">
        <input v-model="printerData.show_map" type="checkbox" id="printerData_show_map" class="custom-control-input">
        <label for="printerData_show_map" class="custom-control-label">{{$t('views.printers.showMap')}}</label>
      </div>
      <div class="form-group">
        <label for="printerData_name">{{$t('views.printers.printerName')}}</label>
        <textarea
            id="printerData_name"
            v-model="printerData.name"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.name.$error }"
        ></textarea>
        <div v-if="submitted && $v.printerData.name.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.name.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.name.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.name.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 50
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_notes">{{$t('views.printers.notes')}}</label>
        <textarea
            id="printerData_notes"
            v-model="printerData.notes"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.notes.$error }"
        ></textarea>
        <div v-if="submitted && $v.printerData.notes.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.notes.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 350
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_name">{{$t('views.printers.printerCurrency')}}</label>
        <select v-model="printerData.currency_id" class="form-control form-control-sm">
          <option v-for="item in currencyList" :key="item.id" :value="item.id">{{item.currency_name}}</option>
        </select>
      </div>

      <!--      printer_location-->
      <div class="form-group">
        <label for="printerData_location_adress">{{$t('views.printers.address')}}</label>
        <textarea
            id="printerData_location_adress"
            v-model="printerData.printerLocation.address"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.printerLocation.address.$error }"
        ></textarea>
        <div v-if="submitted && $v.printerData.printerLocation.address.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.printerLocation.address.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.printerLocation.address.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.printerLocation.address.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 250
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_location_directions">{{$t('views.printers.howToFind')}}</label>
        <textarea
            rows="6"
            id="printerData_location_directions"
            v-model="printerData.printerLocation.directions"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.printerLocation.directions.$error }"
        ></textarea>
        <div v-if="submitted && $v.printerData.printerLocation.directions.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.printerLocation.directions.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.printerLocation.directions.minLength">
            {{$t('validatePhrases.minLength')}}: 3
          </span>
          <span v-if="!$v.printerData.printerLocation.directions.maxLength">
            {{$t('validatePhrases.maxLength')}}: 500
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_location_longtitude">{{$t('views.printers.longitude')}}</label>
        <input
            id="printerData_location_longtitude"
            v-model="printerData.printerLocation.longtitude"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.printerLocation.longtitude.$error }"
        />
        <small class="form-text text-muted">{{$t('popularWords.example')}}: 37.595270</small>
        <div v-if="submitted && $v.printerData.printerLocation.longtitude.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.printerLocation.longtitude.required">{{$t('validatePhrases.requiredField')}}</span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_location_latitude">{{$t('views.printers.latitude')}}</label>
        <input
            id="printerData_location_latitude"
            v-model="printerData.printerLocation.latitude"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.printerLocation.latitude.$error }"
        />
        <small class="form-text text-muted">{{$t('popularWords.example')}}: 55.736037</small>
        <div v-if="submitted && $v.printerData.printerLocation.latitude.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.printerLocation.latitude.required">{{$t('validatePhrases.requiredField')}}</span>
        </div>
      </div>
      <div class="form-group">
        <setter-metro v-model="printerData.printerLocation.metro_station_id"></setter-metro>
      </div>
      <div class="form-group">
        <label for="printerData_name">{{$t('views.printers.timezone')}}</label>
        <select v-model="printerData.printerLocation.timezone" class="form-control form-control-sm">
          <option v-for="item in timezones" :key="item.id" :value="item.id">{{item.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="printerData_schedule">{{$t('views.printers.schedule')}}</label>
        <week-scheduler
            :default="printerWorkSchedule"
            @emitComponent="setWorkSchedule"
        ></week-scheduler>
      </div>
      <div class="form-group">
        <label for="printerData_schedule_custom">{{$t('views.printers.scheduleCustom')}}</label>
        <printer-custom-schedule
          :default="printerWorkScheduleCustom"
          @emitComponent="setWorkScheduleCustom"
        ></printer-custom-schedule>
      </div>
      <div class="form-group">
        <label for="printerData_name">{{$t('views.printers.franchise')}}</label>
        <select v-model="printerData.printerFranchiseRelation.franch_rel_id" class="form-control form-control-sm">
          <option v-for="item in legalRelations" :key="item.id" :value="item.id">
            {{item.legalMain.legal_person_name}} (->{{item.legalSlave.legal_person_name}})
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="printerData_partner_percent">{{$t('views.printers.percentToPartner')}}</label>
        <input
            id="printerData_partner_percent"
            v-model="printerData.printerFranchiseRelation.partner_percent"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.printerFranchiseRelation.partner_percent.$error }"
        />
        <div v-if="submitted && $v.printerData.printerFranchiseRelation.partner_percent.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.printerFranchiseRelation.partner_percent.minValue">
            {{$t('validatePhrases.minValue')}}: 0
          </span>
          <span v-if="!$v.printerData.printerFranchiseRelation.partner_percent.maxValue">
            {{$t('validatePhrases.maxValue')}}: 100
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_partner_percent">{{$t('views.printers.prices')}}</label>
        <printer-price
            :default="printerData.printerPrice"
            :is-production="+printerData.is_production === 1?true:false"
            @emitComponent="setPrice"
        ></printer-price>
      </div>


    </form>
  </div>
</template>

<script>

import {maxLength, maxValue, minLength, minValue, required} from "vuelidate/lib/validators";
//import axios from "axios";
import WeekScheduler from "@/components/WeekScheduler";
import PrinterPrice from "@/components/management/printers/PrinterPrice";
import CopyArrayAndObject from "../../libraries/CopyArrayAndObject";
import PrinterCustomSchedule from "./PrinterCustomSchedule.vue";
import SetterMetro from '../metro/setterMetro.vue';

const copyArrObj = new CopyArrayAndObject();

export default {
  name: "EditPrinter",
  components: { PrinterPrice, WeekScheduler, PrinterCustomSchedule, SetterMetro },
  data(){
    return {
      printerWorkSchedule: [],
      printerWorkScheduleCustom: [],
      printerData: {
        code: "",
        currency_id: 1,
        ip: "",
        is_color: 1,
        is_on: 1,
        show_map: 0,
        is_production: 0,
        login: "",
        name: null,
        password: "",
        port: "",
        notes: "",
        printerFranchiseRelation: {franch_rel_id: 0, partner_percent: null},
        printerLocation: {
          printer_id: '', 
          latitude: '', 
          longtitude: '',
          directions: '', 
          address: '', 
          launch_date: '',
          metro_station_id: "0",
          timezone: "3"
        },
        printerPrice: {
          COLOR_A3: null,
          COLOR_A4: "0",
          COLOR_DUPLEX_A3: null,
          COLOR_DUPLEX_A4: "0",
          GRAYSCALE_A3: null,
          GRAYSCALE_A4: "0",
          GRAYSCALE_DUPLEX_A3: null,
          GRAYSCALE_DUPLEX_A4: "0",
        },
        printerWorkSchedule: [],
        printerWorkScheduleCustom: [],
        serial_number: ""
      },
      submitted: false,
    }
  },
  props: {
    printer: Object,
    currencyList: Array,
    metroStations: Array,
    timezones: Object,
    //franchises: Array,
    legalRelations: Array,
    submitPrinter: Number
  },
  watch: {
    printer: function(prnt){
      let obj = {};
      for(let key in prnt){
        obj[key] = prnt[key];
      }
      this.printerData = obj;
      this.printerWorkSchedule = copyArrObj.copy(this.printerData.printerWorkSchedule);
      this.printerWorkScheduleCustom = copyArrObj.copy(this.printerData.printerWorkScheduleCustom);
    },
    submitPrinter: function(val){
      if(val > 0){
        this.submitEditPrinter();
      }
    }
  },
  computed: {

  },
  validations: {
    printerData: {
      serial_number: { required, minLength: minLength(3), maxLength: maxLength(35) },
      ip: { required, minLength: minLength(10), maxLength: maxLength(20) },
      port: { required, minLength: minLength(3), maxLength: maxLength(6) },
      code: { required, minLength: minLength(6), maxLength: maxLength(6) },
      login: { required, minLength: minLength(3), maxLength: maxLength(65) },
      password: { required, minLength: minLength(3), maxLength: maxLength(65) },
      name: { required, minLength: minLength(3), maxLength: maxLength(50) },
      notes: { maxLength: maxLength(350) },
      printerLocation: {
        address: { required, minLength: minLength(3), maxLength: maxLength(250) },
        directions: { required, minLength: minLength(3), maxLength: maxLength(500) },
        longtitude: { required },
        latitude: { required }
      },
      printerFranchiseRelation: {
        franch_rel_id: { required, minValue: minValue(1) },
        partner_percent: { required, minValue: minValue(0), maxValue: maxValue(100) }
      }
    }
  },
  methods: {
    setWorkSchedule(val){
      this.printerData.printerWorkSchedule = val;
    },
    setWorkScheduleCustom(val){
      this.printerData.printerWorkScheduleCustom = val;
    },
    setPrice(val){
      this.printerData.printerPrice = val;
    },
    submitEditPrinter(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.printerData.$touch();
      if(!this.$v.printerData.$invalid){
        this.$emit('submitEditPrinter', this.printerData);
      }
    },
  },
  created() {

  }

};
</script>

<style scoped lang="css">

</style>
